import React from 'react'
import { FooterContainer, FooterLinkItems, SocialMedia, SocialMediaWrap, SocialEmail, WebsiteRights } from './FooterElements'

function Footer(props) {
	return (
		<FooterContainer>
			
			<SocialMedia>
				<SocialMediaWrap>
				<FooterLinkItems>For more information, please contact us at <SocialEmail href="mailto:info@cuetessa.com">info@cuetessa.com</SocialEmail></FooterLinkItems>
					<WebsiteRights>Cuetessa, Inc © {new Date().getFullYear()}</WebsiteRights>
				</SocialMediaWrap>
			</SocialMedia>

		</FooterContainer>
	)
}

export default Footer