import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const FooterContainer = styled.footer`
	background: rgba(0, 0, 0, 0);
	height: 80px;
	margin-top: -80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	position:fixed;
	bottom: 0;
	z-index: 10;
	width: 100%;
	
	
		@media screen and (max-height: 350px) {
		bottom: -35px;
	}
`

export const FooterWrap = styled.div`
	//padding:0px 24px;
	display:flex;
	//align-items:center;
	width:100%;
	margin-left:20px;
	margin-bottom:40px;
`

export const FooterLinksContainer = styled.div`
display:flex;
justify-content:center;
`

export const FooterLinksWrapper = styled.div`
display:flex;
`

export const FooterLinkItems = styled.div`
display:flex;
flex-direction:column;
align-items:left;
text-align:left;
box-sizing:border-box;
color:#fff;
//justify-self: left;
margin-bottom:60px;
font-size:0.6rem;
@media screen and (max-width:768px) {
		font-size: .7rem;
	}
	@media screen and (max-width: 480px) {
		font-size: .65rem;
	}
`

export const FooterLink = styled(Link)`
	color:#fff;
	text-decoration:none;
	margin-bottom:0.5rem;
	font-size:33px;
	&:hover{
		color:#ECD4CA;
		transition:0.3s ease-out;
	}
	@media screen and (max-width:768px) {
		font-size: 24px;
	}
	@media screen and (max-width: 480px) {
		font-size: 20px;
	}
`;


export const SocialMedia = styled.section`
	//max-width:100%;
	width:100%;
	margin-left:30px;
	margin-right:30px;

	@media screen and (max-width:550px) {
		margin-left:25px;
	margin-right:25px;
	}
	
`

export const SocialMediaWrap = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:center;
	width:100%;
	
	
`

export const SocialEmail = styled.a`
	color:#fff;
	justify-self:start;
	cursor:pointer;
	text-decoration:none;
	font-size:0.75rem;
	display:flex;
	align-items:center;
	
	&:hover{
		color:#ECD4CA;
		transition:0.3s ease-out;
	}
	@media screen and (max-width:768px) {
		font-size: .7rem;
	}
	@media screen and (max-width: 480px) {
		font-size: .65rem;
	}
`

export const WebsiteRights = styled.small`
color:#fff;
margin-bottom:60px;
font-size:0.6rem;
justify-self: right;

@media screen and (max-width:768px) {
	font-size: .6rem;
}
@media screen and (max-width: 480px) {
	font-size: .5rem;
}
`

export const SocialIcons = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
width:170px;
color:#fff;
margin-bottom:60px;
margin-right:15px;
@media screen and (max-width:768px) {
	width:160px;
}
@media screen and (max-width: 480px) {
	width:140px;
}
`

export const SocialIconLink = styled.a`
color:#fff;
font-size:24px;
`

export const StyledSlot2 = styled.div` 
  
flex: 1;
align-content: center;
justify-content: center;
width: 94%;
height: 1px;
background-color: #fff;
top: 0;
z-index: 11;
opacity:0.15;
margin-bottom:20px;
margin-left:30px;
  
`
