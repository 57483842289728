import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'


export const HeroContainer = styled.div`
	//background: #0c0c0c;
	display: flex;
	justify-content: left;
	align-items: top;
	//padding: 0 30px;
	height: 600px;
	position: relative;
	overflow
	
	z-index: 1;
    
`

export const HeroBg = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
    filter: brightness(60%);
`

export const VideoBg = styled.video`
    //position: absolute;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	//background: #232a34;
`

export const HeroContent = styled.div`
	z-index: 3;
	max-width: 500px;
	position: fixed;
	//padding: 8px 24px;
	display: flex;
	flex-direction: column;
	align-items: left;
    padding-top: 200px;
	padding-left:30px;

	@media screen and (max-width:550px) {
		padding-left:25px;
	}

	
		@media screen and (max-height: 550px) {
		padding-top: 100px;
	}
		@media screen and (max-height: 450px) {
		padding-top: 50px;
	}
`

export const HeroText = styled.div`
	color: #fff;
	font-family:'Montserrat';
	font-size: 23px;
	text-align: left;
    white-space: pre-wrap;
	font-weight:bold;
	
	@media screen and (max-width:768px) {
		font-size: 22px;
	}
	@media screen and (max-width: 480px) {
		font-size: 20px;
	}
`

export const HeroP = styled.p`
	margin-top: 24px;
	color: #fff;
	font-size: 16px;
	text-align: left;
	max-width: 600px;
	font-family: 'Oxygen', sans-serif;
	@media screen and (max-width: 768px) {
		font-size: 14px;
	}
	@media screen and (max-width: 480px) {
		font-size: 12px;
	}
`

export const HeroBtnWrapper = styled.div`
	margin-top: 32px;
	text-align: left;
`

export const ArrowForward = styled(MdArrowForward)`
margin-left:3px;
margin-right:-7px;
font-size:20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
margin-left:3px;
margin-right:-7px;
font-size:20px;
`

export const Button = styled.button`
  color: black;
  border: 1px solid black;
  border-radius: 30px;
  font-size: 12px;
  background: white;
  padding: 9px 16px;
`;