import React from 'react'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import Footer from '../components/Footer';
import {ImageBg} from '../components/AppComponents/appElements'

function LandingPage() {
	const toggle = () => {}
  return(
		<div>
			<ImageBg/>
			<Navbar toggle={toggle}/>
			<HeroSection/>
			<Footer/>
    </div>
  )
}

export default LandingPage