import React from 'react'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroText } from './HeroElements'


function HeroSection(props) {

const VidSrc1 = 'https://smoothiimages.s3.us-west-1.amazonaws.com/cuetessaweb.mp4'
  return (
  	<HeroContainer>
  	  <HeroBg>
				<VideoBg playsInline autoPlay muted id="bgvid">
					<source src={VidSrc1} type="video/mp4"/>
				</VideoBg>
  	  </HeroBg>
  	  <HeroContent>
			<HeroText>We are a startup working on a new musical experience. </HeroText>
			<HeroText>Great things coming soon!</HeroText>
  	  </HeroContent>
  	</HeroContainer>
  )
}

export default HeroSection