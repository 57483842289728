import styled from 'styled-components'



export const ImageBg = styled.div`
	background-color:black;
	min-height:100%;
	background-position: top;
	background-size: cover;
	background-attachment: scroll;
	background-repeat: no-repeat;
	position: fixed;
	top: 0;
	bottom:0;
	left: 0;
	right: 0;
	z-index: -1;	
  
`

