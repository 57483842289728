import LandingPage from './pages/landingPage';

function App() {
  return(
    <div >
      <LandingPage/>
    </div>
  )
 
}

export default App;
