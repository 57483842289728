import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
//import { Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
  background: rgba(0, 0, 0, 0);
	height: 100px;
	display: flex;
	align-items: center;
	font-size: 1rem;
	position: sticky;
	top: 0;
	z-index: 10;
	
	@media screen and (max-width: 960px) {
		transition: 0.8s all ease;
	}
`

export const StyledSlot = styled.div`
  flex: 1;
  align-content: center;
  justify-content: center;
  width: 94%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 0;
  z-index: 11;
  margin-top: 80px;
  opacity:0.15;
`;

export const NavbarContainer = styled.div`

	z-index: 1;

	
	
	
`

export const LogoBg = styled.img`
	width:310px;
	
	flex: 1;
	margin-left: -50px;


	@media screen and (max-width: 500px) {
		margin-left: -55px;
	}
`

export const MobileIcon = styled.div`
	display: ${({ isLanding }) => (isLanding ? 'block' : 'none')};
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-100%, 45%);
	color: #fff;
	font-size: 1.8rem;
	cursor: pointer;
	//top: 50%;
	
	//@media screen and (max-width: 768px) {
	//	display: block;
	//	position: absolute;
	//	top: 0;
	//	right: 0;
	//	transform: translate(-100%,40%);
	//	font-size: 1.8rem;
	//	cursor: pointer;
	//	color: #fff;
	//}
`

export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	margin-right: -22px;
	@media screen and (max-width: 769px){
		display: none;
	}
`

export const NavItem = styled.li`
	height: 80px;
`
 //styled(LinkS)`
export const NavLinks = styled.li`
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	&.active {
		border-bottom: 3px solid #01bf71;
	}
`

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	@media screen and (max-width: 768) {
		display: none;
	}
`

export const NavBtnLink = styled(LinkR)`
	border-radius: 50px;
	background: #01bf71;
	white-space: nowrap;
	padding: 10px 22px;
	color: #010606;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	&.hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #010606;
	}
`
export const StartOver = styled.div`
	display: ${({ isPlayer }) => (isPlayer ? 'block' : 'none')};
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-10%, 100%);
	color: #fff;
	font-size: 0.6rem;
	cursor: pointer;
	
	
`

export const BtnStartOver = styled.button`
  color: ${({ isSelected }) => (isSelected ? 'rgb(0, 0, 0, 1)' : 'rgb(256, 256, 256, 1)')};
  border: 2px solid white;
  border-radius: 30px;
  font-size: 12px;
  background: ${({ isSelected }) => (isSelected ? 'rgb(256, 256, 256, 1)' : 'rgb(0, 0, 0, 0)')};
  padding: 8px 20px;
  margin: 2em 2em;
  width: 120px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  
`;