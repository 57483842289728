import React from "react";
import { Nav, NavbarContainer, LogoBg, StyledSlot} from './NavbarElements'
//https://www.youtube.com/watch?v=Nl54MJDR2p8



const Navbar = () => {
  return (
    <Nav>
      <NavbarContainer>
        <LogoBg src={'cuetessaLogos/CuetessaClearBkg.png'} />
        
      </NavbarContainer>
      <StyledSlot></StyledSlot>
    </Nav>
  )
}

export default Navbar